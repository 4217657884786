:root {
    --primary: #008578;
    --secondary: #0088CC;
    --warning: #850000;
    --background: #F2F2F2;
    --foreground: #FFFFFF;
    --input: #FAFAFA;
}

@font-face {
    font-family: "Pieta";
    src: local("Pieta"),
    url("/public/fonts/Pieta.ttf") format("truetype");
    font-weight: normal;
}

body {
    margin: 0;
    font-family: Pieta, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background);
}

.text-primary {
    color: var(--primary);
}

.text-secondary {
    color: var(--secondary);
}

* {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
*::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.input-field {
    background-color: var(--input);
    height: 4em;
}

.input-bg {
    background-color: var(--input);
}

.pill-radius {
    border-radius: 2em;
}

.std-radius {
    border-radius: 1em;
}

.title-margin {
    margin-left: 1em;
}

.title {
    font-weight: bold;
    font-size: 2em;
}

