@import "constants.css";


.login-container {
    @apply px-10 py-5;
    text-align: left;
    background-color: var(--foreground);
    width: 40vw;
    min-width: 25em;
    max-width: 35em;
}

.login-container h3 {
    font-size: 2.2em;
    padding: 0;
    /*margin: 0;*/
}

.login-container .label-text {
    font-size: 1.2em;
}

.divLogo {
    overflow: hidden;
    height: 200px;
    z-index: 1;
    display: flex;
    position: absolute;
    top: -200px;
    left: 50%;
    transform: translate(-50%, 0);
}
