@tailwind base;
@tailwind components;
@tailwind utilities;

@import "constants.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
