@tailwind base;
@tailwind components;
@tailwind utilities;

@import "constants.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.centeredbox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
}

.inputfield {
  background-color: #fafafa;
}

.shadow-10 {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}

.content-panel {
  /*@apply rounded-2xl;*/
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  padding-top: 2em;
  padding-left: 1em;
  padding-right: 1em;
  /*background-color: #fff;*/
  width: calc(100vw - 20rem);
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
}

.content {
  align-content: start;
}

.modal {
  opacity: 1;
  visibility: visible;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
}

.h-scroll {
  overflow: auto;
  height: calc(100vh - 11em);
}

.h-scroll-2 {
  overflow: auto;
  height: calc(100vh - 13em);
}

.h-scroll-3 {
  overflow: auto;
  height: calc(100vh - 16em);
}

.MuiPaper-rounded {
  border-radius: 2em !important;
}

.sidebar-bottom-btn {
  @apply bg-neutral rounded-2xl m-2 px-4 py-3 cursor-pointer;
}

.sidebar-bottom-btn:hover {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  transition: 0.3s;
  --tw-bg-opacity: 0.05;
}
